/* eslint-disable brace-style */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExternalLinks } from '../models/ExternalLinks';
import Urls from '../constants/Urls';

@Injectable({ providedIn: 'root' })
export class SSOService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
  private _showAppSwitcher = false;

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([this.isAuthenticated$, this.isDoneLoading$]).pipe(
    map((values) => values.every((b) => b))
  );

  constructor(private oauthService: OAuthService, private router: Router) {
    // this.oauthService.redirectUri = environment.authConfig.redirectUri + '/silent-refresh.html';
    // THe following cross-tab communication of fresh access tokens works usually in practice,
    // but if you need more robust handling the community has come up with ways to extend logic
    // in the library which may give you better mileage.
    //
    // See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    //
    // Until then we'll stick to this:
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.logout();
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });
    this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

    // this.oauthService.events.pipe(filter((e) => ['token_received'].includes(e.type))).subscribe(() => this.oauthService.loadUserProfile());

    this.oauthService.events.pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type))).subscribe(() => this.logout());

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return (
      this.oauthService
        .loadDiscoveryDocument()
        // 1. HASH LOGIN:
        // Try to log in via hash fragment after redirect back
        // from IdServer from initImplicitFlow:
        .then(() => this.oauthService.tryLogin())
        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            return Promise.resolve();
          }
          // 2. SILENT LOGIN:
          // Try to log in via a refresh because then we can prevent
          // needing to redirect the user:
          return this.oauthService
            .silentRefresh()
            .then(() => Promise.resolve())
            .catch((result) => {
              // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
              // Only the ones where it's reasonably sure that sending the
              // user to the IdServer will help.
              const errorResponsesRequiringUserInteraction = [
                'interaction_required',
                'login_required',
                'account_selection_required',
                'consent_required',
              ];

              if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
                // 3. ASK FOR LOGIN:
                // At this point we know for sure that we have to ask the
                // user to log in, so we redirect them to the IdServer to
                // enter credentials.
                //
                // ALWAYS force a user to login.
                this.login();
                return Promise.resolve();
              }

              // We can't handle the truth, just pass on the problem to the
              // next handler.
              return Promise.reject(result);
            });
        })

        .then(() => {
          this.isDoneLoadingSubject$.next(true);

          // Check for the strings 'undefined' and 'null' just to be sure. Our current
          // login(...) should never have this, but in case someone ever calls
          // initImplicitFlow(undefined | null) this could happen.
          if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
            let stateUrl = this.oauthService.state;
            if (stateUrl.startsWith('/') === false) {
              stateUrl = decodeURIComponent(stateUrl);
            }

            if (stateUrl.indexOf(`${Urls.REGISTER}`) > -1) {
              this.router.navigate([`${Urls.APP}/${Urls.HOMEPAGE}`]);
            } else {
              this.router.navigateByUrl(stateUrl);
            }
          }
        })
        .catch(() => this.isDoneLoadingSubject$.next(true))
    );
  }

  public login(targetUrl?: string) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() {
    this.oauthService.logOut({ client_id: this.oauthService.clientId });
  }
  public refresh() {
    this.oauthService.silentRefresh();
  }
  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  get accessToken() {
    return this.oauthService.getAccessToken();
  }
  get isLoggedIn(): boolean {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }
  get decodedAccessToken() {
    return this.accessToken ? JSON.parse(atob(this.accessToken.split('.')[1])) : '';
  }
  get externalApplicationLinks() {
    const externalLinks: ExternalLinks[] = [];
    const tokenLinks = this.decodedAccessToken.links;
    if (tokenLinks) {
      for (let index = 0; index < tokenLinks.length; index++) {
        const link = tokenLinks[index];
        const parsedLinkData = JSON.parse(link);
        const origin = environment.url;

        Object.keys(parsedLinkData).forEach((key) => {
          const splitKey = key.split('_');
          const appTranslationKey = splitKey[1].indexOf('gs') > -1 ? 'GENERAL.EXTERNAL_LINKS.GIVING_SPACE' : 'GENERAL.EXTERNAL_LINKS.PORTAL';
          const exists = externalLinks.find((el) => el.tenantName === `GENERAL.EXTERNAL_LINKS.${splitKey[0].toUpperCase()}`);
          const url = parsedLinkData[key];

          if (exists) {
            const existingApplication = exists.applications.find((a) => a.name === appTranslationKey);
            if (!existingApplication) {
              exists.applications.push({
                name: appTranslationKey,
                url,
                selected: url.indexOf(origin) > -1,
              });
            }

            if (!this._showAppSwitcher) {
              this._showAppSwitcher = exists.applications.length > 1;
            }
          } else {
            externalLinks.push({
              tenantName: `GENERAL.EXTERNAL_LINKS.${splitKey[0].toUpperCase()}`,
              applications: [
                {
                  name: appTranslationKey,
                  url,
                  selected: url.indexOf(origin) > -1,
                },
              ],
            });
          }
        });
      }
    }
    return externalLinks;
  }
  get showAppSwitcher() {
    return this._showAppSwitcher;
  }
}
