import { EnvironmentName, TenantId } from 'src/app/_core/constants/EnvironmentConst';
import { Environment } from 'src/app/_core/models/IEnvironment';

export const environment: Environment = {
  name: EnvironmentName.TB,
  tenantId: TenantId.TRUSTBRIDGE_PROD,
  defaultLanguage: 'en-tb',
  appVersion: require('../../package.json').version,
  production: false,
  url: 'https://givingspace.trustbridgeglobal.com',
  server: 'https://givingspace.trustbridgeglobal.com/api',
  authServer: 'https://givingspace.trustbridgeglobal.com/api/auth',
  mattermostUrl: 'https://tgf-east.mattermost.givingspace.com/',
  placesApiKey: 'AIzaSyCDVRL0Igtki_L6-xHqyquPHdLZ3D3GpXA',
  recaptchaApiKey: '6Ld9tNYfAAAAAInURAhAM34V5OmNg74xWnH4Ib19',
  favicon: 'assets/env-assets/tb/favicon.ico',
  authBackground: 'assets/env-assets/tb/bg.jpg',
  whiteLogo: 'assets/env-assets/tb/logo-white.png',
  blackLogo: 'assets/env-assets/tb/logo-black-v2.png',
  termsOfUse: 'https://www.trustbridgeglobal.com/terms',
  privacyPolicy: 'https://www.trustbridgeglobal.com/privacy',
  emailAddress: 'admin@trustbridgeglobal.com',
  hasCause: true,
  hasCodeOfConduct: true,
  isEnglishDefault: true,
  authConfig: {
    issuer: 'https://sso.benevatech.com/auth/realms/BenevaTech',
    redirectUri: window.location.origin,
    clientId: 'swiss_prod-gs-public',
    responseType: 'code',
    scope: 'openid profile email links',
    showDebugInformation: true,
    disableAtHashCheck: true,
    sessionChecksEnabled: true,
    useSilentRefresh: true,
  },
  keycloakRealm: 'BenevaTech',
};
