import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { PageFilters } from 'src/app/_core/models/filters/Filters';

@Component({
  selector: 'app-filters-search-and-sort',
  templateUrl: './filters-search-and-sort.component.html',
  styleUrls: ['./filters-search-and-sort.component.scss'],
})
export class FiltersSearchAndSortComponent implements OnDestroy {
  @Output() filtersApplied: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() filterMenu: PageFilters<any>;
  @Input() hasFilters: boolean = true;
  @Input() filtersCollapsed: boolean = false;
  @Input() hasSort: boolean = true;
  @Input() sortByLabel: boolean = true;
  @Input() toggleListView: boolean = false;

  subscriptions: Subscription = new Subscription();
  isTable: boolean;
  isTablet: boolean;

  // prettier ignore
  constructor(private activatedRoute: ActivatedRoute, private breakpointObserver: BreakpointObserver) {
    this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => (this.isTable = params.get('view') === 'table'));
    this.subscriptions.add(
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(() => {
        this.isTablet = breakpointObserver.isMatched(Breakpoints.Small) || breakpointObserver.isMatched(Breakpoints.XSmall);
      })
    );
  }

  ngOnDestroy(): void {}

  onFiltersApplied(arg = null): void {
    if (typeof arg === 'boolean') {
      this.filtersApplied.emit(arg);
    } else {
      this.filtersApplied.emit(arg || '');
    }
  }
}
