import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { FormControl } from '@angular/forms';
import { AddressComponentTypes } from '../../../_core/constants/GooglePlaces';
import Utils from '../../../_core/helpers/Utils';
import { CustomAddress } from '../../../_core/models/CustomAddress';

@Component({
  selector: 'app-google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss'],
})
export class GooglePlacesComponent implements OnInit {
  @Input() streetAddress: boolean;
  @Input() fullAddress = null;
  @Output() addressChanged: EventEmitter<CustomAddress> = new EventEmitter<CustomAddress>();

  address: FormControl;

  constructor() {}

  ngOnInit(): void {
    this.address = new FormControl(this.fullAddress);
  }

  handleAddressChange(address: Address): void {
    const customAddress = new CustomAddress(
      Utils.getGooglePlacesData(address.address_components, [AddressComponentTypes.ROUTE])?.long_name,
      Utils.getGooglePlacesData(address.address_components, [AddressComponentTypes.STREET_NUMBER])?.long_name,
      Utils.getGooglePlacesData(address.address_components, [AddressComponentTypes.COUNTRY])?.long_name,
      Utils.getGooglePlacesData(address.address_components, [AddressComponentTypes.COUNTRY])?.short_name,
      Utils.getGooglePlacesData(address.address_components, [AddressComponentTypes.ADMINISTRATIVE_AREA_LEVEL_1])?.long_name,
      Utils.getGooglePlacesData(address.address_components, [
        AddressComponentTypes.LOCALITY,
        AddressComponentTypes.POSTAL_TOWN,
        AddressComponentTypes.SUBLOCALITY_LEVEL_1,
        AddressComponentTypes.ADMINISTRATIVE_AREA_LEVEL_1,
      ])?.long_name,
      address.geometry.location.lat(),
      address.geometry.location.lng(),
      null,
      address.formatted_address
    );
    const addressNotFound = this.streetAddress
      ? !customAddress.streetName || !customAddress.country || !customAddress.city
      : !customAddress.country || !customAddress.city;
    if (addressNotFound) {
      this.address.setErrors({ invalid: addressNotFound });
    }
    this.addressChanged.emit(customAddress);
  }
}
