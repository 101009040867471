<div class="d-flex w-100 align-items-center justify-content-between justify-self-end">
  <div *ngIf="hasFilters && !filtersCollapsed" [ngClass]="isTablet ? 'd-none' : 'd-flex'">
    <app-filters [filters]="filterMenu?.filters" (triggerRequest)="onFiltersApplied()"></app-filters>
  </div>
  <div
    class="d-flex flex-grow-1 mb-1 mb-sm-0 justify-content-end"
    [ngClass]="{ 'flex-sm-row flex-column-reverse align-items-sm-center align-items-end': hasFilters, 'align-items-center': !hasFilters }"
  >
    <app-search-bar [filterMenu]="filterMenu" (searchEvent)="onFiltersApplied($event)"> </app-search-bar>
    <div class="d-flex my-1" [class.ml-1]="hasFilters || hasSort">
      <div *ngIf="hasFilters && isTablet" class="d-flex" [class.d-none]="!filtersCollapsed">
        <app-filters [collapsed]="true" [filters]="filterMenu.filters" (triggerRequest)="onFiltersApplied($event)"></app-filters>
      </div>
      <app-sort-by
        *ngIf="hasSort"
        [showText]="sortByLabel"
        [sortByOptions]="filterMenu?.sortOptions"
        (triggerRequest)="onFiltersApplied()"
      ></app-sort-by>
      <ng-container *ngIf="toggleListView">
        <div class="d-flex align-items-center">
          <p class="m-0 mr-4 font-24 text-gray">|</p>
          <i
            class="fas fa-th-large font-21 mr-3 pointer text-light-gray"
            [class.text-black]="!isTable"
            (click)="isTable = false; viewChanged.emit(isTable)"
          ></i>
          <i class="fas fa-list font-21 pointer text-light-gray" [class.text-black]="isTable" (click)="isTable = true; viewChanged.emit(isTable)"></i>
        </div>
      </ng-container>
    </div>
  </div>
</div>
