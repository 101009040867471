import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { SSOService } from './sso.service';
import { ssoAppInitializerFactory } from './sso-app-initializer.factory';
import { ssoConfig } from './sso.config';
import { ssoModuleConfig } from './sso-module.config';
import { UserService } from '../services/user.service';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    SSOService
  ],
})
export class SSOModule {
  static forRoot(): ModuleWithProviders<SSOModule> {
    return {
      ngModule: SSOModule,
      providers: [
        { 
          provide: APP_INITIALIZER, 
          useFactory: ssoAppInitializerFactory, 
          deps: [SSOService, UserService], 
          multi: true 
        },
        { provide: AuthConfig, useValue: ssoConfig },
        { provide: OAuthModuleConfig, useValue: ssoModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: SSOModule) {
    if (parentModule) {
      throw new Error('SSOModule is already loaded. Import it in the AppModule only');
    }
  }
}
