import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const ssoConfig: AuthConfig = {
  issuer: environment.authConfig.issuer,
  clientId: environment.authConfig.clientId,
  responseType: environment.authConfig.responseType,
  redirectUri: environment.authConfig.redirectUri,
  // silentRefreshRedirectUri: environment.authConfig.redirectUri + '/silent-refresh.html',
  scope: environment.authConfig.scope,
  useSilentRefresh: environment.authConfig.useSilentRefresh,
  sessionChecksEnabled: environment.authConfig.sessionChecksEnabled,
  showDebugInformation: environment.authConfig.showDebugInformation,
  silentRefreshTimeout: 5000,
};
