import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FileUploadModule } from 'ng2-file-upload';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule } from 'ngx-mask';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CoreModule } from '../_core/core.module';
import { DealCarouselComponent } from './components/deal-carousel/deal-carousel.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { NextPrevButtonsComponent } from './components/next-prev-buttons/next-prev-buttons.component';
import { NoItemsFoundComponent } from './components/no-items-found/no-items-found.component';
import { ErrorComponent } from './components/page-not-found/error.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ReadMoreButtonComponent } from './components/read-more-button/read-more-button.component';
import { RestrictedAreaComponent } from './components/restricted-area/restricted-area.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SortByComponent } from './components/sort-by/sort-by.component';
import { UploadProgressBarComponent } from './components/upload-progress-bar/upload-progress-bar.component';
import { RecaptchaDirective } from './directives/recaptcha.directive';
import { StickyHeaderDirective } from './directives/sticky-header.directive';
import { EnumToStringPipe } from './pipes/enum-to-string.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { NegativeNumberPipe } from './pipes/negative-numbers.pipe';
import { ObjectIterationPipe } from './pipes/object-iteration.pipe';
import { SanitizeResourceUrlPipe } from './pipes/sanitize-url.pipe';
import { SortByPipe } from './pipes/sort.pipe';
import { TruncateCharactersPipe } from './pipes/truncate.pipe';
import { VariableToStringPipe } from './pipes/variable-to-string.pipe';
import { WebsitePipe } from './pipes/website.pipe';
import { CancelChangesComponent } from './_modals/cancel-changes/cancel-changes.component';
import { CropModalComponent } from './_modals/crop-modal/crop-modal.component';
import { DeleteConfirmationComponent } from './_modals/delete-confirmation/delete-confirmation.component';
import { MediaComponent } from './_modals/media/media.component';
import { ModalContainerComponent } from './_modals/modals-container/modals-container.component';
import { StoryModalComponent } from './_modals/story-modal/story-modal.component';
import { FormControlValidationComponent } from './components/form-control-validation/form-control-validation.component';
import { GooglePlacesComponent } from './components/google-places/google-places.component';
import { FiltersComponent } from './components/filters/filters.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ExtractArrayFromObjectPipe } from './pipes/extract-array-from-object.pipe';
import { BoldedTitleDirective } from './directives/bolded-title.directive';
import { ProjectUpdatesCarouselComponent } from './components/project-updates-carousel/project-updates-carousel.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { QuillModule } from 'ngx-quill';
import { MediaInfoComponent } from './components/media-info/media-info.component';
import { FormatNumberInput } from './directives/number-formatter.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { CurrencyComponent } from './components/currency/currency.component';
import { SearchEntityComponent } from './components/search-entity/search-entity.component';
import { ActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { UpdateCardComponent } from './components/update-card/update-card.component';
import { MatCardModule } from '@angular/material/card';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { ChipListAutocompleteDirective } from './directives/chip-list-autocomplete.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TitlecaseNamePipe } from './pipes/titlecase-name.pipe';
import { FiltersSearchAndSortComponent } from './components/filters-search-and-sort/filters-search-and-sort.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { SingleWordPipe } from './pipes/single-word.pipe';
import { DocumentCardComponent } from './components/document-card/document-card.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MainButtonComponent } from './components/main-button/main-button.component';
import { LegalComponent } from 'shared/components/legal/legal.component';
import { TranslateCutPipe } from './pipes/translate-cut.pipe';
import { CutSeparatorPipe } from './pipes/cut-separator.pipe';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { SharedButtonsComponent } from './components/shared-buttons/shared-buttons.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareButtonsModalComponent } from './_modals/share-buttons-modal/share-buttons-modal.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SearchEntityMultiSelectComponent } from './components/search-entity-multi-select/search-entity-multi-select.component';
import { BoldSubstringPipe } from './pipes/bold-substring.pipe';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { IdleEntityModalComponent } from './_modals/idle-entity/idle-entity-modal.component';
import { FormatNumberPipe } from './pipes/format-number.pipe';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faSquareXTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';

const icons = [
  // ... other icons
  faSquareXTwitter,
  faXTwitter
];

const shareProp = {
  twitter: {
    icon: ['fab', 'x-twitter'],
    color: '#0b0b0c'
  }
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatButtonModule,
    MatSelectModule,
    MatChipsModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatGridListModule,
    MatCheckboxModule,
    MatInputModule,
    ImageCropperModule,
    FileUploadModule,
    CarouselModule,
    InfiniteScrollModule,
    NgxMaskModule.forRoot(),
    SlickCarouselModule,
    LazyLoadImageModule,
    NgxDocViewerModule,
    GooglePlaceModule,
    TranslateModule,
    MatExpansionModule,
    QuillModule,
    MatBadgeModule,
    MatCardModule,
    MatSlideToggleModule,
    ShareButtonsModule.withConfig({ prop: shareProp }),
    ShareIconsModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatChipsModule
  ],

  declarations: [
    FooterComponent,
    LoadingSpinnerComponent,
    ProgressBarComponent,
    ErrorComponent,
    ModalContainerComponent,
    MediaComponent,
    SortByComponent,
    FiltersComponent,
    LoaderComponent,
    CropModalComponent,
    NoItemsFoundComponent,
    CancelChangesComponent,
    DeleteConfirmationComponent,
    SearchBarComponent,
    UploadProgressBarComponent,
    DealCarouselComponent,
    ScrollTopComponent,
    ReadMoreButtonComponent,
    MediaInfoComponent,
    StoryModalComponent,
    LegalComponent,
    RestrictedAreaComponent,
    TruncateCharactersPipe,
    EnumToStringPipe,
    WebsitePipe,
    StickyHeaderDirective,
    SortByPipe,
    ObjectIterationPipe,
    VariableToStringPipe,
    FilterPipe,
    SanitizeResourceUrlPipe,
    NegativeNumberPipe,
    RecaptchaDirective,
    FormControlValidationComponent,
    GooglePlacesComponent,
    ExtractArrayFromObjectPipe,
    BoldedTitleDirective,
    ProjectUpdatesCarouselComponent,
    SanitizeHtmlPipe,
    FormatNumberInput,
    CurrencyComponent,
    SearchEntityComponent,
    NextPrevButtonsComponent,
    ActionButtonsComponent,
    UpdateCardComponent,
    TextEditorComponent,
    ChipListAutocompleteDirective,
    TitlecaseNamePipe,
    FiltersSearchAndSortComponent,
    MaintenanceComponent,
    SingleWordPipe,
    DocumentCardComponent,
    MainButtonComponent,
    TranslateCutPipe,
    CutSeparatorPipe,
    FileUploadComponent,
    SharedButtonsComponent,
    ShareButtonsModalComponent,
    SearchEntityMultiSelectComponent,
    BoldSubstringPipe,
    IdleEntityModalComponent,
    FormatAddressPipe,
    FormatNumberPipe
  ],

  exports: [
    InfiniteScrollModule,
    MatButtonModule,
    MatDividerModule,
    TranslateModule,
    CurrencyComponent,
    LoadingSpinnerComponent,
    FooterComponent,
    ErrorComponent,
    ModalContainerComponent,
    ProgressBarComponent,
    FiltersComponent,
    SortByComponent,
    LoaderComponent,
    NoItemsFoundComponent,
    CropModalComponent,
    CancelChangesComponent,
    DeleteConfirmationComponent,
    SearchBarComponent,
    UploadProgressBarComponent,
    DealCarouselComponent,
    ScrollTopComponent,
    ReadMoreButtonComponent,
    StoryModalComponent,
    LegalComponent,
    MediaInfoComponent,
    GooglePlaceModule,
    RestrictedAreaComponent,
    TruncateCharactersPipe,
    EnumToStringPipe,
    WebsitePipe,
    StickyHeaderDirective,
    SortByPipe,
    ObjectIterationPipe,
    VariableToStringPipe,
    FilterPipe,
    NegativeNumberPipe,
    SanitizeResourceUrlPipe,
    RecaptchaDirective,
    FormControlValidationComponent,
    GooglePlacesComponent,
    ExtractArrayFromObjectPipe,
    NextPrevButtonsComponent,
    BoldedTitleDirective,
    ProjectUpdatesCarouselComponent,
    SanitizeHtmlPipe,
    FormatNumberInput,
    MatTooltipModule,
    LazyLoadImageModule,
    SearchEntityComponent,
    MatBadgeModule,
    ActionButtonsComponent,
    MatChipsModule,
    UpdateCardComponent,
    TextEditorComponent,
    ChipListAutocompleteDirective,
    MatProgressSpinnerModule,
    TitlecaseNamePipe,
    FiltersSearchAndSortComponent,
    DocumentCardComponent,
    MatIconModule,
    MatSelectModule,
    MatIconModule,
    SingleWordPipe,
    MatSlideToggleModule,
    MainButtonComponent,
    TranslateCutPipe,
    CutSeparatorPipe,
    FileUploadComponent,
    SharedButtonsComponent,
    ShareButtonsModule,
    ShareIconsModule,
    SearchEntityMultiSelectComponent,
    BoldSubstringPipe,
    IdleEntityModalComponent,
    FormatAddressPipe,
    FormatNumberPipe
  ]
})
export class SharedModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(...icons);
  }
}
