export enum ServerError {
  INVALID_GRANT = 'invalid_grant',
  EMAIL_USED = 'EMAIL_USED',
  NO_USER = 'USER_NOT_FOUND',
  INVALID_PASSWORD_FORMAT = 'INVALID_PASSWORD_FORMAT',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
  TOKEN_INVALID = 'invalid_token',
  NEW_PASSWORD_SAME_AS_OLD = 'NEW_PASSWORD_SAME_AS_OLD',
  INVALID_OLD_PASSWORD = 'INVALID_OLD_PASSWORD',
  ROLE_INVALID = 'ROLE_INVALID',
  ASSET_HAS_NULL_FIELDS = 'ASSET_HAS_NULL_FIELDS',
  EQUITY_FUND_HAS_NULL_FIELDS = 'EQUITY_FUND_HAS_NULL_FIELDS',
  ASSET_NOT_VISIBLE = 'ASSET_NOT_VISIBLE',
  DELETE_USER_ERROR = 'DELETE_USER_ERROR',
  CANNOT_DELETE_OWN_USER = 'CANNOT_DELETE_OWN_USER',
  SPONSOR_ON_PROJECT = 'SPONSOR_ON_PROJECT',
  INSUFFICIENT_PERMISSION = 'INSUFFICIENT_PERMISSION',
  ROLE_CHANGE_INVALID = 'ROLE_CHANGE_INVALID',
  PARTNER_ALREADY_ASSIGNED = 'PARTNER_ALREADY_ASSIGNED',
  COMPANY_ADMIN_ASSIGNED = 'COMPANY_ADMIN_ASSIGNED',
  COMPANY_HAS_PUBLISHED_DEALS = 'COMPANY_HAS_PUBLISHED_DEALS',
  LINK_AND_VIDEO_CANNOT_COEXIST = 'LINK_AND_VIDEO_CANNOT_COEXIST',
  NUMBER_OF_BANNERS_EXCEEDED = 'NUMBER_OF_BANNERS_EXCEEDED',
  MINIMUM_NUMBER_OF_BANNERS_REACHED = 'MINIMUM_NUMBER_OF_BANNERS_REACHED',
  FIRM_HAS_PUBLISHED_FUNDS = 'FIRM_HAS_PUBLISHED_FUNDS',
  FIRM_ADMIN_ASSIGNED = 'FIRM_ADMIN_ASSIGNED',
  NAME_EMPTY = 'NAME_EMPTY',
  INVESTMENT_TYPE_NOT_SELECTED = 'INVESTMENT_TYPE_NOT_SELECTED',
  NO_INVESTOR_OPTION_SELECTED = 'NO_INVESTOR_OPTION_SELECTED',
  NO_PROFILE = 'NO_PROFILE',
  CAPTCHA_INVALID = 'CAPTCHA_INVALID',
  COMPANY_ADMIN_NOT_FOUND = 'COMPANY_ADMIN_NOT_FOUND',
  REQUIRED_FIELDS_NOT_POPULATED = 'REQUIRED_FIELDS_NOT_POPULATED',
  PROJECT_HAS_NULL_FIELDS = 'PROJECT_HAS_NULL_FIELDS',
  PROJECT_HAS_PUBLISHED_DEALS = 'PROJECT_HAS_PUBLISHED_DEALS',
  ORGANISATION_NOT_PUBLISHED = 'ORGANISATION_NOT_PUBLISHED',
  ORGANISATION_HAS_NULL_FIELDS = 'ORGANISATION_HAS_NULL_FIELDS',
  ORGANISATION_HAS_PUBLISHED_PROJECTS = 'ORGANISATION_HAS_PUBLISHED_PROJECTS',
  PROJECT_MANAGER_INCOMPLETE_PROFILE = 'PROJECT_MANAGER_INCOMPLETE_PROFILE',
  NOT_PERMITTED = 'NOT_PERMITTED',
  DUPLICATED_GOALS = 'DUPLICATED_GOALS',
  DUPLICATED_PARTNER_ORGANISATIONS = 'DUPLICATED_PARTNER_ORGANISATIONS',
  NUMBER_OF_FINANCIAL_STATEMENTS_REQUIRED_NOT_REACHED = 'NUMBER_OF_FINANCIAL_STATEMENTS_REQUIRED_NOT_REACHED',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  NUMBER_OF_CHARACTERS_NOT_REACHED = 'NUMBER_OF_CHARACTERS_NOT_REACHED',
  AWS_ERROR_UPLOAD = 'AWS_ERROR_UPLOAD',
  PORTFOLIO_HAS_NULL_FIELDS = 'PORTFOLIO_HAS_NULL_FIELDS',
  MAXIMUM_LIVE_PROJECTS_EXCEEDED = 'MAXIMUM_LIVE_PROJECTS_EXCEEDED',
  INVALID_COLLECTION_ENTITY = 'INVALID_COLLECTION_ENTITY',
  PROJECT_NOT_VISIBLE = 'PROJECT_NOT_VISIBLE',
  UUID_NOT_FOUND = 'UUID_NOT_FOUND',
  PREV_PASSWORD_NOT_ALLOWED = 'PREV_PASSWORD_NOT_ALLOWED',
}
